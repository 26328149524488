import IconAdd from '../../../assets/icons/add.svg';
import IconAddWhite from '../../../assets/icons/add-white.svg';
import IconList from '../../../assets/icons/list.svg';
import IconListWhite from '../../../assets/icons/list-white.svg';
import IconNotifications from '../../../assets/icons/notifications.svg';
import IconSettings from '../../../assets/icons/settings.svg';
import IconLogout from '../../../assets/icons/logout.svg';
import IconHelp from '../../../assets/icons/help.svg';
import IconAccount from '../../../assets/icons/user.svg';
import IconCompany from '../../../assets/icons/office.svg';

export const NAV_ITEMS = [
    {
        itemName: "Home",
        page: '/home'
    },
    {
        itemName: "Sales",
        dropDownItems: [
            {
                id: 'list_sales_orders',
                img: IconList,
                text: 'Sales Orders',
                modal: false,
                page: '/sales-orders'
            },
            {
                id: 'list_customers',
                img: IconList,
                text: 'Customers',
                modal: false,
                page: '/customers'
            },
            {
                id: 'list_customer_groups',
                img: IconList,
                text: 'Customer Groups',
                modal: false,
                page: '/customer-groups'
            }
        ]
    },
    {
        itemName: "Inventory",
        dropDownItems: [
            {
                id: 'items',
                img: IconList,
                text: 'Products',
                modal: false,
                page: '/items'
            },
            {
                id: 'item_groups',
                img: IconList,
                text: 'Categories',
                modal: false,
                page: '/item-groups'
            },
            {
                id: 'items',
                img: IconList,
                text: 'Stock Transfers',
                modal: false,
                page: '/stock-transfers'
            },
            {
                id: 'list_unit_measures',
                img: IconList,
                text: 'Unit of Measures',
                modal: false,
                page: '/unit-of-measures'
            },
            {
                id: 'list_item_taxes',
                img: IconList,
                text: 'Item Taxes',
                modal: false,
                page: '/item-taxes'
            },
            {
                id: 'list_location',
                img: IconList,
                text: 'Locations',
                modal: false,
                page: '/locations'
            },
            // {
            //     id: 'list_work_orders',
            //     img: IconList,
            //     text: 'Work Orders',
            //     modal: false,
            //     page: '/work-orders'
            // }
        ]
    },
    {
        itemName: "Purchasing",
        dropDownItems: [
            {
                id: 'purchase_orders',
                img: IconList,
                text: 'Purchase Orders',
                modal: false,
                page: '/purchase-orders'
            },
            {
                id: 'list_suppliers',
                img: IconList,
                text: 'Suppliers',
                modal: false,
                page: '/suppliers'
            },
            {
                id: 'list_supplier_groups',
                img: IconList,
                text: 'Supplier Groups',
                modal: false,
                page: '/supplier-groups'
            },
        ]
    },
    // {
    //     itemName: "Finacial",
    //     dropDownItems: [
    //         {
    //             id: 'list_item_tax',
    //             img: IconList,
    //             text: 'Item Taxes',
    //             modal: false,
    //             page: '/item-taxes'
    //         }
    //     ]
    // },
    // {
    //     itemName: "HR",
    //     dropDownItems: [
    //         {
    //             id: 'employees',
    //             img: IconList,
    //             text: 'Employees',
    //             modal: false,
    //             page: '/employees'
    //         },
    //         {
    //             id: 'payroll',
    //             img: IconList,
    //             text: 'Payroll',
    //             modal: false,
    //             page: '/payroll'
    //         },
    //         {
    //             id: 'attendance',
    //             img: IconList,
    //             text: 'Attendance',
    //             modal: false,
    //             page: '/attendance'
    //         },
    //         {
    //             id: 'departments',
    //             img: IconList,
    //             text: 'Departments',
    //             modal: false,
    //             page: '/departments'
    //         },
    //         {
    //             id: 'designations',
    //             img: IconList,
    //             text: 'Designations',
    //             modal: false,
    //             page: '/designations'
    //         }
    //     ]
    // },
    {
        itemName: "Report",
        dropDownItems: [
            {
                id: 'sales_reports',
                img: IconList,
                text: 'Sales',
                modal: false,
                page: '/sales-reports'
            },
            {
                id: 'inventory_reports',
                img: IconList,
                text: 'Inventory',
                modal: false,
                page: '/inventory-reports'
            },
            {
                id: 'purchase_reports',
                img: IconList,
                text: 'Purchasing',
                modal: false,
                page: '/purchase-reports'
            },
           
        ]
    },
]

export const SETTING_NOTFICATION_BAR = [
    {
        itemName: "Settings",
        icon: IconSettings,
        dropDownItems: [
            {
                id: 'accounts',
                icon: IconAccount,
                text: 'Accounts',
                modal: false,
                page: '/accounts'
            },
            {
                id: 'company',
                icon: IconCompany,
                text: 'Comapny',
                modal: false,
                page: '/company'
            },
            {
                id: 'help',
                icon: IconHelp,
                text: 'Help',
                modal: false,
                page: '/help',
                
            },
            {
                id: 'logout',
                icon: IconLogout,
                text: 'logout',
                modal: false,
                page: '/logout',
                breakpoint: true
            },
        ]
    },
]



