import { format } from 'date-fns';
import ListActions from '../../../components/list-actions/list-actions.componenet';
import ListTableMainCell from '../../../components/list-table-main-cell/list-table-main-cell.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';

import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../../utils/column-values.util';

const ENDPOINT = "/items";

export const ITEM_ORDERS_COLUMNS = [
    {
        Header: "Product Code",
        accessor: "itemCode",
        style: {
            position: "sticky",
            left:0,
            top:0,
            zIndex: 2
        },
        Cell: ({ value, row }) => {
            const uuid = row.original.uuid;
            return (
                <ListTableMainCell
                    value={value}
                    uuid={uuid}
                />
            )
        }
    },
    {
        Header: "Product Name",
        accessor: "itemName",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Category",
        accessor: "itemGroupCode",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Unit of Measure",
        accessor: "unitOfMeasureCode",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    // {
    //     Header: "Expiration Date",
    //     accessor: "expirationDate",
    //     Cell: ({ value }) => {
    //         if(value){
    //             return format(new Date(value), 'dd/MM/yyyy')
    //         }else{
    //             return "-"
    //         }
    //     }
    // },
    {
        Header: "Qty on Hand",
        accessor: "totalQtyOnHand",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Unit Price (ETB)",
        accessor: "unitSellingPrice",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    },
    {
        Header: "Total Value (ETB)",
        accessor: "totalValue",
        Cell: ({ value }) => {
            return displayCellValueNumberToTwoDecimal(value);
        }
    }
]